import React , { useState } from 'react';
import { useTranslation } from "react-i18next";
import './ContactUs.css';
import { Container, Row, Col } from 'react-bootstrap';
import ContactForm from '../../Components/ContactForm/ContactForm';
import contact_us from '../../Assets/contact_us.jpg';

function ContactUs() {
    const { t } = useTranslation();
    const [AddressShow, setAddressShow] = useState(false);
    const [EmailShow, setEmailShow] = useState(false);
    const [PhoneShow, setEmailPhone] = useState(false);
    const handleClickAdrees = (e) => {
        setAddressShow(!AddressShow)
    }
    const handleClickEmail = (e) => {
        setEmailShow(!EmailShow)
    }
    const handleClickPhone = (e) => {
        setEmailPhone(!PhoneShow)
    }

    return (

            <Container fluid="md" className='contactus-container'>
                <Row className='justify-content-md-center contact-row'>
                    <Col xs={12} md={7} className='ContactUs-container'>
                        <Row>
                            <span className='contact-heading'>{t('How to Reach Us')}</span>
                        </Row>
                        <Row>
                            <span className='contact-details'>{t("Get in touch with our team and let's discuss how we can assist you. We're here to help!")}</span>
                        </Row>
                    </Col>
                    <Col xs={12} md={5} className='ContactUs-img-container'>
                        <img  className='ContactUs' src={contact_us} alt="ContactUs" />
                    </Col>
                </Row>
                <Row className='justify-content-md-center contact-row contact-intro'>
                    <Col xs={12} md={12}>
                        <Row style={{marginBottom: "20px"}}>
                            <Col xs={12} md={12} >
                                <span className='contact-content-heading'>{t('Contact Information')}</span>
                            </Col>
                        </Row>
                        <Row>
                        {
                            !AddressShow ? (
                                <>
                                    <Col xs={12} md={12} className='contact-details-informations' onClick={handleClickAdrees}>
                                        <span className='contact-details' >{t("Address")}</span>
                                    </Col>
                                </>
                            ): (
                                <>
                                    <Col xs={12} md={12} className='contact-details-informations-show' >
                                        <span className='contact-details' onClick={handleClickAdrees}>{t("Address")}</span> <br />
                                        <span className='contact-details' >{t("942/69 Charn Issara Tower 2nd Floor Rama IV Road, Suriya Wong, Bang Rak, Bangkok 10500, Thailand")}</span> 
                                    </Col>
                                </>
                            )
                        }
                        </Row>
                        <Row>
                        {
                            !EmailShow ? (
                                <>
                                    <Col xs={12} md={12} className='contact-details-informations' onClick={handleClickEmail}>
                                        <span className='contact-details'>{t("Email")}</span>
                                    </Col>
                                </>
                            ): (
                                <>
                                    <Col xs={12} md={12} className='contact-details-informations-show' >
                                        <span className='contact-details' onClick={handleClickEmail}>{t("Email")}</span> <br />
                                        <span className='contact-details'>contact@dcm.co.th</span> 
                                    </Col>
                                </>
                            )
                        }
                        </Row>
                        <Row>
                        {
                            !PhoneShow ? (
                                <>
                                    <Col xs={12} md={12} className='contact-details-informations' onClick={handleClickPhone}>
                                        <span className='contact-details'>{t("Phone")}</span>
                                    </Col>
                                </>
                            ): (
                                <>
                                    <Col xs={12} md={12} className='contact-details-informations-show' >
                                        <span className='contact-details' onClick={handleClickPhone}>{t("Phone")}</span> <br />
                                        <span className='contact-details'>+66 2233-0055, +66 2233-0068</span> 
                                    </Col>
                                </>
                            )
                        }

                        </Row>
                    </Col>
                </Row>
                <Row className='ustify-content-md-center'>
                    <Col xs={12} md={6}>
                        <ContactForm />
                    </Col>
                    <Col xs={12} md={6}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.8082351059056!2d100.529617233727!3d13.730056970676168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29f2bc2033193%3A0x245a54406c70d286!2sCharn%20Issara%20Tower%201!5e0!3m2!1sen!2sth!4v1670505233182!5m2!1sen!2sth"
                            className='map'
                            style={{ border: "0",width: "100%" }} 
                            allowFullScreen="" 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade"
                            title="myFrameMap"

                        />
                    </Col>
                </Row >
            </Container>
    );
}

export default ContactUs;