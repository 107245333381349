import React from 'react';
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import logo from '../../Assets/dcm_new_logo.png';
import './NavBar.css';
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import getLanguage from '../Translation/Translation';
import DemoModal from '../DemoModal/DemoModal';

export const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'th',
    name: 'Thai'
  }
];

function onLanguageClick(code){
  i18next.changeLanguage(code);

  // localStorage.setItem("language", code ? 'th' : 'en')
}

function NavBarComponent() {
  const { t } = useTranslation();

        return (
          <Navbar collapseOnSelect expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <img className="dcm-logo" src={logo} alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{marginTop: "-20px"}}/>
            <Navbar.Collapse id="responsive-navbar-nav" style={{marginTop: "-20px"}}>
              <Nav className="me-auto">
                <Nav.Link href="/about-dcm">{t('About DCM')}</Nav.Link>
                <Nav.Link href="/services">{t('Services')}</Nav.Link>
                {/* <Nav.Link href="#EBook">{t('E-Book')}</Nav.Link> */}
                <Nav.Link href="/taxation-calendar">{t('Taxation Calendar')}</Nav.Link>
                <Nav.Link href="/contact-us">{t('Contact Us')}</Nav.Link>
              </Nav>
              <Nav className="right-nav">
                <Nav.Link className="demo-link">
                  <DemoModal />
                </Nav.Link>
                <NavDropdown title={t(getLanguage(i18next.language), {})} id="collasible-nav-dropdown">
                  {languages.map(({code,name}) => (
                    <NavDropdown.Item key={code} onClick={() => onLanguageClick(code)}>
                      {t(name)}
                    </NavDropdown.Item>
                    )
                  )}
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        );
};

export default NavBarComponent;