import React, { useRef }  from 'react'
import { Modal, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import DemoForm from '../DemoForm/DemoForm';
import DemoFormMobile from '../DemoForm/DemoFormMobile';
import emailjs from '@emailjs/browser';

function DemoModal() {
    const { t } = useTranslation();

    const [modalShow, setModalShow] = React.useState(false);

    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);

    const [formStatus, setFormStatus] = React.useState('Send');
    const [hintStatus, setHintStatus] = React.useState();

    const form = useRef();
    let btnRef = useRef();

    const onSubmit = (e) => {
        e.preventDefault();
        setFormStatus('Submitting...');
    
        if(btnRef.current){
            btnRef.current.setAttribute("disabled", "disabled");
        };
         
        emailjs.sendForm('service_3qtn24x', 'template_zefk0bw', form.current, 'Fdm3fTzFbMN0GEKR-')
          .then((result) => {
              // console.log(result.text);
              setFormStatus('Sent!');
              setHintStatus('Sent Successfully.');
          }, (error) => {
              // console.log(error.text);
              setHintStatus('Unable to send. Please try again later.');
          });
      };

    return (
        <div onKeyDown={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
        onFocus={e => e.stopPropagation()}
        onMouseOver={e => e.stopPropagation()}>
          
        <Button className="demo-button" onClick={handleShow}>{t('Request a Demo')}</Button>

        <Modal
        show={modalShow}
        onHide={handleClose}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {t('Request a Demo')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>{t('Please enter your information')}</h5>
            {isMobile ?  <DemoFormMobile onSubmit={onSubmit} form={form}/> : <DemoForm onSubmit={onSubmit} form={form}/>}
          </Modal.Body>
          <Modal.Footer>
            <Form.Text className="text-muted">
                {t('We\'ll get back to you within one business day.')}
            </Form.Text>
            <Button variant="primary" type="submit" form="demo-form" onClick={onSubmit} ref={btnRef}>
                {t(formStatus)}
            </Button>
                <h6>{t(hintStatus)}</h6>
          </Modal.Footer>
        </Modal>
        </div>
      );
}

export default DemoModal;