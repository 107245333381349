import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// import { isMobile } from "react-device-detect";
// import HomepageCarousel from '../../Components/HomepageCarousel/HomepageCarousel';
import './Homepage.css';
// import webbar from '../../Assets/DCMWebBar_Mobile.jpeg';
import "bootstrap-icons/font/bootstrap-icons.css";
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import logo from '../../Assets/dcm_new_logo.png';
import aboutus1 from '../../Assets/home-aboutus1.jpeg';
import aboutus2 from '../../Assets/aboutus2.jpeg';
import aboutus3 from '../../Assets/aboutus3.jpeg';
import { useNavigate } from 'react-router-dom';

// function mobileContent() {
//     return (
//         <div className='mobile-content'>
//             <img className="dcm-logo" src={webbar} alt="mobile-webbar" />
//         </div>
//     );
// }

function Homepage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ContactShow, setContactShow] = useState(false);

    const handleClickContactShow = (e) => {
      setContactShow(!ContactShow)
  }
    return (

        <Container fluid="md" className='homepage-container'>
          <Row className="justify-content-md-center home-aboutus-intro">
            <Col xs={12} md={9} >
              <Row >
                  <span className='dcm-heading'>{t('Digital Corporate Management')}</span>
              </Row>
              <Row >
                <span className='homepage-subheading'>{t('Your SME Digital Mate')}</span>
              </Row>
              <Row >
                <span className='homepage-intro'>{t('Discover the future of digital innovation with DCM - your trusted partner in web design, development, and digital transformation.')}</span>
              </Row>
              <Row >
                <Col xs={12}>
                  <Button 
                    className='learnmore-btn' 
                    variant="primary" 
                    size="lg"
                    onClick={() => navigate('/about-dcm')}
                  >{t('Learn More')}</Button>
                  <Button 
                    className='contactus-btn' 
                    variant="outlined-primary" 
                    size="lg"
                    onClick={() => navigate('/contact-us')}
                  >{t('Contact Us')}</Button>
                </Col>
              </Row>
            </Col>
            <Col  xs={12} md={3} className='dcmlogo-container'>
              <Row >
                <img  src={logo} alt="dcmlogo" />
              </Row>
            </Col>
          </Row>


          <Row className="justify-content-md-center home-aboutus-intro">
              <span className='home-content-heading'>{t('About Us')}</span>
              <Col  xs={12} md={4} className="about-us-col">
                <img className='home-aboutus-img' src={aboutus1} alt="aboutus1" />
                <span className='home-aboutus'>{t('Our Mission')}</span><br />
                <span className='home-content'>{t('To empower businesses of all sizes with cutting-edge digital solutions that drive growth, innovation, and success.')}</span>
              </Col>
              <Col  xs={12} md={4} className="about-us-col">
                <img className='home-aboutus-img' src={aboutus2} alt="aboutus2" />
                <span className='home-aboutus'>{t('Our Values')}</span><br />
                <span className='home-content'>{t('Integrity, Creativity, Collaboration, and a Relentless Commitment to Excellence.')}</span>
              </Col>
              <Col  xs={12} md={4} className="about-us-col">
                <img className='home-aboutus-img' src={aboutus3} alt="aboutus3" />
                <span  className='home-aboutus'>{t('Our Expertise')}</span><br />
                <span className='home-content'>{t('Web Design, Web Development, E-commerce, Mobile Apps, and Digital Marketing.')}</span>
              </Col>
          </Row>

          <Row className="justify-content-md-center home-aboutus-row ">
            <h2 className='home-content-heading'>{t('Our Services')}</h2>
            <Col xs={12} md={6}>
              <Row>
                <Col xs={1} md={1}>
                  <i className="bi bi-1-square-fill home-aboutus-number"></i>
                </Col>
                <Col xs={11} md={11} className='home-aboutus-number-descript'> 
                  <Row className='number-span'>
                    <span className='home-content-subheading'>{t('Web Design')}</span>
                  </Row>
                  <Row>
                    <span className='home-content'>{t('Stunning, responsive, and user-friendly websites that captivate your audience.')}</span>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row>
                <Col xs={1} md={1}>
                  <i className="bi bi-2-square-fill home-aboutus-number"></i>
                </Col>
                <Col xs={11} md={11} className='home-aboutus-number-descript'>
                  <Row className='number-span'>
                    <span className='home-content-subheading'>{t('Web Development')}</span>
                  </Row>
                  <Row>
                    <span className='home-content'>{t('Cutting-edge, scalable web applications that deliver exceptional user experiences.')}</span>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-md-center home-aboutus-row">
            <Col xs={12} md={6}>
              <Row>
                <Col xs={1} md={1}>
                  <i className="bi bi-3-square-fill home-aboutus-number"></i>
                </Col>
                <Col xs={11} md={11} className='home-aboutus-number-descript'>
                  <Row className='number-span'>
                    <span className='home-content-subheading'>{t('E-commerce Solutions')}</span>
                  </Row>
                  <Row>
                    <span className='home-content'>{t('Seamless, secure, and optimized online stores that drive sales and customer loyalty.')}</span>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row>
                <Col xs={1} md={1}>
                  <i className="bi bi-4-square-fill home-aboutus-number"></i>
                </Col>
                <Col xs={11} md={11} className='home-aboutus-number-descript'>
                  <Row className='number-span'>
                    <span className='home-content-subheading'>{t('Mobile App Development')}</span>
                  </Row>
                  <Row>
                    <span className='home-content'>{t('Powerful, intuitive, and feature-rich mobile apps that put your business in the palm of your customers\' hands.')}</span>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Button 
            className='ourservices-btn mg-t-mobile' 
            variant="primary" 
            size="lg"
            onClick={() => navigate('/services')}
          >{t('View Our Services')}</Button>


          <Row className="justify-content-md-center card-contact">
            <Card className='card-contact-panding'>
              <Card.Body>
                <Card.Title><span className='home-content-heading'>{t('Contact Us')}</span></Card.Title>
                <Card.Text className='home-content' style={{ paddingTop: "20px" }}>
                  {
                    !ContactShow ? (
                        <>
                            <Row className='content-contact'>
                                <Col xs={12} className='content-contact-col' onClick={handleClickContactShow}>
                                  {t('Get in touch with our experts')}
                                </Col>
                            </Row>
                        </>
                    ): (
                        <>
                            <Row className='content-contact'>
                                <Col xs={12} className='content-contact-col-show' onClick={handleClickContactShow}>
                                  {t('Get in touch with our experts')}
                                </Col>
                            </Row>
                        </>
                    )
                  }
                  {
                    ContactShow && (
                      <>
                        <Row className='content-contact'>
                          <Col xs={12}>
                            {t('Email: ')}admin@dcm.co.th
                          </Col>
                        </Row>
                        <Row className='content-contact'>
                          <Col xs={12}>
                            {t('Phone: ')}+66 2233-0055, +66 2233-0068
                          </Col>
                        </Row>
                        <Row className='content-contact'>
                          <Col xs={12}>
                            {t('Address: ')} {t('942/69 Charn Issara Tower 2nd Floor Rama IV Road, Suriya Wong, Bang Rak, Bangkok 10500, Thailand')}
                          </Col>
                        </Row>
                      </>
                    )
                  }
                </Card.Text>
                {
                    ContactShow && (
                      <>
                        <Button 
                          className='learnmore-btn' 
                          variant="primary" 
                          size="lg" 
                          style={{ marginTop: '1%'}}
                          onClick={() => navigate('/contact-us')}
                        >
                          {t('Send a Message')}
                        </Button>
                      </>
                    )
                  }
                </Card.Body>
            </Card>
          </Row>


      </Container>
    );
}

export default Homepage;