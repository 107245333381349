import React from 'react'
import './Footer.css';
import { useTranslation } from "react-i18next";
import pdf from '../../Assets/DCMPrivacyPolicy.pdf';
import pdfEng from '../../Assets/DCM_Privacy_Policy_(ENG).pdf';
import i18next from 'i18next';

function Footer() {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();
    return (
        <footer id="footer">
            {
                i18next.language == "en" ? (
                    <>
                        <p id="copyright">{t(`© Copyright ${currentYear}. Digital Corporate Management Co., Ltd. All Rights Reserved`)}</p>
                        <p><a href={pdfEng} target="_blank" rel="noreferrer" id="PPlink">{t('Our Privacy Policy')} </a></p>
                    </>
                ): (
                    <>
                        <p id="copyright">{t(`© สงวนลิขสิทธิ์ ${currentYear} บริษัท ดิจิทัล คอร์ปอเรท เมเนจเม้นท์ จำกัด`)}</p>
                        <p><a href={pdf} target="_blank" rel="noreferrer" id="PPlink">{t('Our Privacy Policy')} </a></p>
                    </>
                )
            }
        </footer>
    );
}

export default Footer;